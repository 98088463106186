
import { defineComponent } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import EntityModal from "@/components/modals/forms/entity/EntityModal.vue";
// import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "new-entity-course",
  components: {
    EntityModal,
    Datatable,
  },
  data() {
    return {
      batch: {},
      employee: {},
      details: {},
      weekplan: {},
      entityTypes: [],
      industry: [],
      entityInfos: [],
      divisions: [],
      districts: [],
      subDistricts: [],
      empDistricts: [],
      tableHeader: [
        {
          name: "Actions",
          key: "actions",
          sortable: false,
          width: "150px",
        },

        {
          name: "TI",
          key: "ti",
          sortable: true,
        },
        {
          name: "Course",
          key: "course",
          sortable: true,
        },
        {
          name: "BN",
          key: "bn",
          sortable: true,
        },
        {
          name: "Trainee Count",
          key: "trainee_count",
          sortable: true,
        },
        {
          name: "Pay %",
          key: "pay",
          sortable: true,
        },
        {
          name: "Payment Amount",
          key: "payment_amount",
          sortable: true,
        },
        {
          name: "Bill Amount",
          key: "bill_amount",
          sortable: true,
        },
        {
          name: "Bill No",
          key: "bill_no",
          sortable: true,
        },
        {
          name: "Bill Date",
          key: "bill_date",
          sortable: true,
        },
      ],
      tableData: [
        {
          id: 1,
          ti: "Montage Training & Certification (Bangladesh)",
          course: "Electrical & Navigation Equipment Installation (NE)",
          bn: "",
          trainee_count: "100",
          pay: "20%",
          payment_amount: "7,399.07",
          bill_amount: "1,25,784.22",
          bill_no: "BL-10001",
          bill_date: "2022-01-22",
        },
        {
          id: 2,
          ti: "Mirpur Agricultural Workshop and Training School - Mirpur",
          course: "Welding & Fabrication (NE)",
          bn: "",
          trainee_count: "100",
          pay: "18%",
          payment_amount: "7,399.07",
          bill_amount: "25,784.22",
          bill_no: "BL-10002",
          bill_date: "2022-01-23",
        },
      ],
      loading: false,
      courseInfoData: false,
      showCourseNotice: false,
      showtrainingProviderNotice: false,
    };
  },
  async created() {
    await this.getEntityTypes();
    await this.getEntityInfos();
  },
  methods: {
    async formSubmit() {
      //form submit
    },
    async getEntityInfos() {
      await ApiService.get("entity/infos")
        .then((response) => {
          this.entityInfos = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEntityTypes() {
      await ApiService.get("entity/types")
        .then((response) => {
          this.entityTypes = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    courseInfo() {
      this.courseInfoData = true;
    },
    courseNotice() {
      this.showCourseNotice = true;
    },
    trainingProviderNotice() {
      this.showtrainingProviderNotice = true;
    },
    edit(data) {
      this.emitter.emit("edit-modal-data", data);
    },

    add() {
      this.emitter.emit("add-modal-data", true);
    },
    Approve(id) {
      Swal.fire({
        title: "Are you sure you want to approve it?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Approved!",
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.delete("entity/infos/" + `${id}`)
            .then((response) => {
              this.emitter.emit("infos-updated", true);
              Swal.fire("Deleted!", response.data.message, "success");
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
